import styles from '../css/_description.module.scss';
import img_1 from '../../assets/description/description_1.png'
import img_2 from '../../assets/description/description_2.png'
import {ScrollAnimation} from "@lasbe/react-scroll-animation";

function Description() {
  return (
    <div className={styles.descriptionWrapper}>
      <h1  className={styles.title}>All-In-One, <span style={{color: "darkblue"}}>Newfriends</span></h1>
      <p className={styles.text}>사업자를 위한 모든 서비스를 한 곳에서, 대표님의 시간을 아껴드릴게요.</p>
      <div className={styles.imgContainer}>
        <ScrollAnimation
          startingPoint="bottom"
          duration={1}
          amount="xl"
          delay={0.5}
          repeat
        >
        <img src={img_1} alt={'img'} className={styles.img_1}/>
        </ScrollAnimation>
        <ScrollAnimation
          startingPoint="top"
          duration={1}
          amount="xl"
          delay={0.3}
          repeat
        >
        <img src={img_2} alt={'img'} className={styles.img_2}/>
        </ScrollAnimation>
      </div>
    </div>
  );
}

export default Description;
