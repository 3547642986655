import styles from '../css/_service.module.scss';
import {ScrollAnimation} from "@lasbe/react-scroll-animation";


function Service() {
  return (
    <div className={styles.serviceWrapper}>
      <ScrollAnimation
        startingPoint="bottom"
        duration={2}
        amount="xl"
        delay={0.5}
        repeat
      >
      <div className={`${styles.serviceCard} ${styles.devImg}`} >
        <div>웹 사이트 개발</div>
      </div>
      </ScrollAnimation>
      <ScrollAnimation
        startingPoint="bottom"
        duration={2}
        amount="xl"
        delay={0.5}
        repeat
      >
      <div className={`${styles.serviceCard} ${styles.appImg}`}>
        <div>앱 개발</div>
      </div>
      </ScrollAnimation>
      <ScrollAnimation
        startingPoint="bottom"
        duration={2}
        amount="xl"
        delay={0.5}
        repeat
      >
      <div className={`${styles.serviceCard} ${styles.marketingImg}`}>
        <div>마케팅 대행</div>
      </div>
      </ScrollAnimation>
      <ScrollAnimation
        startingPoint="bottom"
        duration={2}
        amount="xl"
        delay={0.5}
        repeat
      >
      <div className={`${styles.serviceCard} ${styles.designImg}`}>
        <div>디자인 스튜디오</div>
      </div>
      </ScrollAnimation>
      <ScrollAnimation
        startingPoint="bottom"
        duration={2}
        amount="xl"
        delay={0.5}
        repeat
      >
      <div className={`${styles.serviceCard} ${styles.videoImg}`}>
        <div>영상 스튜디오</div>
      </div>
      </ScrollAnimation>
    </div>
  );
}

export default Service;
