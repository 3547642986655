import styles from '../css/_footer.module.scss';
import logo from '../../assets/logo.png'
function Footer() {
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.logoContainer}>
        <img src={logo} alt="logo" />
      </div>
        <table className={styles.footerInfo}>
          <tr>
            <th>오시는 길 : 동탄</th>
            <th>사업자등록번호 : 111-30-20123 </th>
          </tr>
          <tr>
            <th>상호명 : 주식회사 뉴프렌즈 </th>
            <th>신한은행 123-1234-1234-12 (예금주: 홍승표)</th>
          </tr>
          <tr>
            <th>대표자 : 홍승표</th>
            <td>COPYRIGHT 2024 (주)뉴프렌즈 ALL RIGHTS RESERVED.</td>
          </tr>
        </table>
    </div>
  );
}

export default Footer;
