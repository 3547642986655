import React, { useEffect, useRef } from "react";
import styles from "../css/_contact_us.module.scss";

function ContactUs() {
  const mapRef = useRef(null);

  useEffect(() => {
    const { naver } = window;

    if (naver && mapRef.current) {
      const mapOptions = {
        center: new naver.maps.LatLng(37.2117585758806, 127.1028595586), // 초기 중심 좌표
        zoom: 19, // 초기 줌 레벨
        zoomControl: true,
        zoomControlOptions: { //줌 컨트롤의 옵션
          position: naver.maps.Position.TOP_RIGHT
        }
      };

      // 네이버 지도 객체 생성
      const map = new naver.maps.Map(mapRef.current, mapOptions);

      new naver.maps.Marker({
        position: new naver.maps.LatLng(37.2117585758806, 127.1028595586), // 마커 위치
        map: map,
        title: "여기가 바로 위치입니다!",
      });
    }
  }, []);

  return (
    <div className={styles.contactUsWrapper}>
      <h2>오시는 길</h2>
      <div
        ref={mapRef}
        style={{
          width: "100%",
          height: "400px",
          border: "1px solid #ddd",
        }}
      ></div>
    </div>
  );
}

export default ContactUs;
