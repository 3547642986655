import styles from './App.module.scss';
import Main_Slider from "./components/js/Main_Slider";
import Description from "./components/js/Description";
import Service from "./components/js/Service";
import ContactUs from "./components/js/ContactUs";
import {ScrollAnimation} from "@lasbe/react-scroll-animation";

function App() {
  return (
    <div className={styles.App}>
      <div className={styles.sliderContainer}>
        <Main_Slider/>
        <ScrollAnimation
          startingPoint="left"
          duration={1}
          amount="xl"
          delay={1}
          repeat
        >
        <hr className={styles.hr1}/>
        </ScrollAnimation>
        <Description/>
        <ScrollAnimation
          startingPoint="right"
          duration={1}
          amount="xl"
          delay={1}
          repeat
        >
        <hr className={styles.hr2}/>
        </ScrollAnimation>
        <Service/>
        <ScrollAnimation
          startingPoint="bottom"
          duration={1}
          amount="xl"
          delay={0.5}
          repeat
        >
        <hr />
        </ScrollAnimation>.
        <ContactUs/>
      </div>
    </div>
  );
}

export default App;
