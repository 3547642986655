import styles from '../css/_header.module.scss';
import {headerMenu_1, headerMenu_2, headerMenu_3, headerMenu_4} from '../../MainInfo';
import logo from '../../assets/logo.png'
function Header() {
  return (
    <div className={styles.headerWrapper}>
      <div className={styles.logo}><img src={logo} alt={''}/></div>
      <ul className={styles.headerMenu_container}>
        <li>{headerMenu_1}</li>
        <li>{headerMenu_2}</li>
        <li>{headerMenu_3}</li>
        <li>{headerMenu_4}</li>
      </ul>
    </div>
  );
}

export default Header;
