import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from '../css/_main_silder.scss'
import main_1 from '../../assets/mainSlide/main_1.png'
import main_2 from '../../assets/mainSlide/main_2.png'
import main_3 from '../../assets/mainSlide/main_3.png'

const Slick = () => {


  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 2500
  };

  return (

      <Slider {...settings} >
        <div>
          <img  src={main_1} alt={'main_1'}/>
        </div>
        <div>
          <img src={main_2} alt={'main_1'}/>
        </div>
        <div>
          <img src={main_3} alt={'main_1'}/>
        </div>
      </Slider>

  );

};

export default Slick;